//@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

[v-cloak] {display: none}

.quotes{
    display: none;
}

.font-montserrat{
    font-family: 'Montserrat', sans-serif;
}

.font-quicksand{
    font-family: 'Quicksand', sans-serif;
}

.swal2-icon.swal2-question::before{
    height: 100%!important;
}

$blue: #1fc4c7;

label.required::after{
    content: '*';
    color: red;
    position: absolute;
    top: -1;
}

*::placeholder{
    color: #adadad !important;
}

//FONT TA A SER IMPORTADA NO TOPO DA APP.SCSS
body{
    padding: 0;
    //font-family: "Nunito", 'sans-serif';
    font-family: 'Kumbh Sans', sans-serif;
    background-color: #1e1d1d;
    color: #ccc;
}

.loggedBody{
    background-color: #f8f9fd;
    color: #1e1d1d;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.loggedNavbar{
    border-bottom: 1px solid #444;
}

.font-weight-600{
    font-weight: 600;
}

.font-weight-300{
    font-weight: 300;
}

.smallDate{
    position: relative;
    bottom: 5px;
    color: #fff;
}

.roundedModal{
    border-radius: 1rem;

    .leftSidePayModal{
        border-radius: 1rem 0 0 1rem;
    }
}

.eachPadNumber{
    border: 2px solid #ccc;
    border-radius: 50%;
    padding: 13px 16.5px;

    &.eachPadNumberOut{
        background-color: #dc3545;
        padding: 14px 6.5px;
        font-size: 14px;
    }

    &.eachPadNumberIn{
        background-color: #28a745;
        padding: 13.5px 14px;
        font-size: 14px;
    }
}

.visibility-hidden{
    visibility: hidden;
}

.display-1{
    font-size: 3rem;
}

.color-777{
    color: #777;
}

.color-333{
    color: #333;
}

#numberPad{
    display: grid;
    grid-template-rows: 75px 75px 75px 75px;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    justify-items: center;
    width: 100%;
    max-width: 300px;

    .numberPad1{
        grid-column: 1;
        grid-row: 1;
    }

    .numberPad2{
        grid-column: 2;
        grid-row: 1;
    }

    .numberPad3{
        grid-column: 3;
        grid-row: 1;
    }

    .numberPad4{
        grid-column: 1;
        grid-row: 2;
    }

    .numberPad5{
        grid-column: 2;
        grid-row: 2;
    }

    .numberPad6{
        grid-column: 3;
        grid-row: 2;
    }

    .numberPad7{
        grid-column: 1;
        grid-row: 3;
    }

    .numberPad8{
        grid-column: 2;
        grid-row: 3;
    }

    .numberPad9{
        grid-column: 3;
        grid-row: 3;
    }

    .numberPadOut{
        grid-column: 1;
        grid-row: 4;
    }

    .numberPad0{
        grid-column: 2;
        grid-row: 4;
    }

    .numberPadIn{
        grid-column: 3;
        grid-row: 4;
    }
}

a{
    color: #ccc;
    transition: .2s ease-out;

    &:hover{
        text-decoration: none;
        color: #bbb;
    }
}

.publicHomeBannerFormDiv{
    padding: 115px 0;
}

.bg-transparent{
    background-color: transparent;
}

.loginCardDiv{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 6px rgb(0 0 0) !important;
}

.registerCardDiv{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 6px rgb(0 0 0) !important;
}

.register-form{
    label{
        color: #333;
    }
}

.publicHomeForm{
    margin-top: 10px;
    padding: 25px 25px;
    background-color: white;
    border-radius: 5px;
}
.navbar-sticky{
    position: sticky;
    padding: 1rem 1rem;
    top: 0;
    z-index: 20;
    border-bottom: 1px solid #5f717b;
}

.smallDeleteButton{
    padding: 2px 6px;
}

.homeBgImg .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
}

.homeBgImg{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/home-img.jpg");
    background-position: center;
}

.loginImage{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/login-image2.jpg");
    background-position: center;
    border-radius: 0 20px 20px 0;
}

.customSearchForm{
    border-radius: 30px;
    border: 2px solid #fff;
    overflow: hidden;
}

.customSearchFormFirstInput{
    padding-left: 30px;
    padding-right: 15px;
    background-color: transparent;
    height: 60px;
    outline: 0;
    border: 0;
    color: white;
    width: 100%;
    border-left: 1px solid #eee;
}

.customSearchFormSelect{
    background-color: transparent;
    width: 150px;
    border: 0;
    height: 60px;
    color: white;
    padding: 10px;

    &:focus{
        outline: none;
    }

    option{
        color: black;
    }
}

.customSearchFormSearch{
    background-color: #fff;
    align-items: center;
    text-align: center;

    button{
        -webkit-appearance: none;
        outline: 0;
        border: 0;
        background-color: transparent;
        line-height: 58px;
        text-align: center;

        i{
            font-size: 20px;
        }
    }
}

.homeMainH3{
    color: white;
    font-size: 40px;
}

.homeSubTitle{
    font-size: 18px;
}

.colorWhite{
    color: white;
}

.multiselect__tags{
    border: 1px solid #ced4da;
}

input::placeholder {
    color: #adadad;
}

.homeBgImg2{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/home-img-2.jpg");
}

.navbar-light .navbar-toggler{
    border-color: transparent;
    padding: 0.35rem 0.75rem 0 0.75rem !important;

    &:focus{
        outline: none;
    }
}

.navbar-light .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.registerSection{
    padding: 25px 0;
}

.br-5{
    border-radius: 5px;
}

.card{
    //box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    margin-bottom: 24px;
}

.signupContent{
    padding: 75px 0;
}

.containerLogin{
    background: #fff;
    margin: 0 auto;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.customCard{
    box-shadow: 0px 15px 16px 0.17px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    background-color: white;
    border-radius: 10px;
}


.hamburguer{
    height: 24px;
}

.hamburger-box{
    width: 30px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
    width: 30px;
    height: 2px;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after{
    background-color: #000;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
    background-color: white;
}

.navSidebar{
    width: 100%;
    height: 100%;
    right: -100%;
    position: fixed;
    transition: .5s ease-out;
    background-color: #fff;
    z-index: 19;

    &.open{
        right: 0;
    }
}

.topbarNav{
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li{
        padding: 5px 10px;
        border-radius: 5px;
        display: inline-block;
        margin-left: 25px;
        transition: .3s;

        a{
            color: #fff;
            font-weight: 400;
            font-size: 16px;

            &:hover{
                text-decoration: none;
            }

            i{
                padding-right: 3px;
            }
        }
    }

    li:hover{
        background-color: #ffffff30;

        a{
            color: #eee;
        }
    }
}

.table thead th{
    border-bottom: inherit;
}

.vaMiddleTable tbody tr td{
    vertical-align: middle;
}

.navSidebarUl{
    list-style: none;
    padding: 10px;
    margin: 0;

    li{
        padding: 5px 10px;
        border-radius: 5px;
        transition: .3s;
        margin-bottom: 10px;

        a{
            color: #fff;
            font-weight: 400;
            font-size: 16px;
            display: flex;
            align-items: center;

            &:hover{
                text-decoration: none;
            }

            i{
                font-size: 24px;
                padding-right: 10px;
            }
        }
    }

    li:hover{
        background-color: #f4f5f7;

        a{
            color: #eee;
            font-weight: 400;
        }
    }
}

.navSidebarUlAlt{
    padding-top: 78px;

    li{
        a{
            color: #333;
        }
    }

    li:hover{
        background-color: #5f717b;

        a{
            color: #eee;
            font-weight: 400;
        }
    }
}

.loginCardHeader{
    border-bottom: none;
    background-color: #31424a;
    color: white;
}

.eachHomeEstablishment{
    .bgDiv{
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.placeholderImgEstablishment{
    max-width: 300px;
}

.eachPlateMenu li{
    padding: 12px 20px;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
}

.eachSectionInMenu{
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 5px;
}

.eachHomeEstablishmentInside{
    background-color: white;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    box-shadow: 0px 2px 6px 0.17px rgba(0, 0, 0, 0.1)
}

.navbarApp{
    background-color: #31424a;
}

.unstyledBtn{
    -webkit-appearance: none;
    outline: none;
    border: none;
    background-color: transparent;

    &:focus{
        outline: none;
    }
}

li.getTheMenuBtn{
    border: 2px solid #fff;
    color: #fff;
    cursor:pointer;
    font-weight: 600;
    border-radius: 20px;
    padding: 5px 15px;

    &:hover{
        color: white;
        background-color: #ffffff30;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.footerHome{
    background-color: #31424a;
    color: #ddd;

    h5{
        font-weight: 600;
    }

    li{
        padding-bottom: 5px;
    }

    a{
        color: inherit;
    }
}

.subTopNavbar{
    background-color: white;
    display: flex;
    align-items: center;
}

.space-between{
    justify-content: space-between;
}

.loginAndRegisterWarning{
    padding: 10px;
    background-color: #eee;
    color: #212529;
    border-radius: 5px;
}

.footerDivider{
    border-top: 1px solid #ddd;
}

.homeQRCodeBtn{
    width: 100%;
    height: 100%;
    background-color: white;
    font-size: 18px;
    font-weight: 700;

    i{
        font-size: 22px;
        vertical-align: middle;
    }

    span{
        vertical-align: middle;
    }
}

.subTopNavbar-right{
    text-align: right;
    padding-right: 30px;

    img{
        height: 100%;
        padding: 10px;
        display: inline-block;
        max-height: 50px;
        border-radius: 50%;
    }

    p{
        margin: 0;
        display: inline-block;
        vertical-align: text-top;
    }
}

.siteShowActionsWrapper{
    a, button{
        font-size: 14px;
    }
}

/*.page-title{
    font-weight: 600;
}*/

.pointer-events-none{
    pointer-events: none;
}

#monitorGrid{
    height: 100%; //FALLBACK TO OLD BROWSERS
    height: 100vh;
    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-rows: 15% 67% 18%;
    text-transform: uppercase;

    &.simplified2{
        grid-template-columns: 100%;
        grid-template-rows: 100%;

        .left-top{
            color: #fff;

            &.greenLeftTop{
                background-color: #00a915;
            }

            &.yellowLeftTop{
                background-color: #a98c00;
            }

            &.redLeftTop{
                background-color: #a90000;
            }

            h5{
                color: #fff;
                margin-bottom: 0;
                font-size: 60px;
            }

            p{
                padding-top: 5px!important;
                font-size: 25px;
            }
        }
    }

    &.smallGrid{
        grid-template-columns: 100%;

        .monitorLight{
            max-width: 300px!important;
        }

        .left-top{
            h5{
                font-size: 30px;
            }

            p{
                padding-top: 5px!important;
                font-size: 20px;
            }
        }
    }

    .left-top{
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column: 1;
        display: grid;
        align-content: center;
        justify-items: center;
        text-align: center;
        color: #333;
        background-color: #fff;

        .monitorLight{
            width: 50%;
            border-radius: 50%;
            display: grid;
            align-items: center;
            justify-content: center;

            i{
                font-size: 10vw;
                color: white;
            }

            &.redLight{
                background-color: #f30808;
                box-shadow: 0px 5px 30px #f30808;
            }

            &.yellowLight{
                background-color: #f3d408;
                box-shadow: 0px 5px 30px #f3d408;
            }

            &.greenLight{
                background-color: #08f33f;
                box-shadow: 0px 5px 30px #08F33F;
            }
        }

        h5{
            color: #333;
            font-weight: 600;
        }
    }

    .left-bottom{
        grid-row: 3;
        grid-column: 1;
        background-color: #fff;
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: 1fr 1fr 1fr;

        > div{
            display: grid;
            justify-items: center;

            .eachMonitorIcon{
                //background-color: #3d4953;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: grid;
                align-content: center;
                justify-content: center;
                color: #3d4953;
                font-size: 35px;
                border: 1px solid #ccc;
                text-align: center;

                img{
                    height: 35px;
                }
            }

            span{
                padding-top: 6px;
                color: #232c37;
                font-size: 14px;
            }
        }
    }

    .right-top{
        grid-row: 1;
        grid-column: 2;
        display: grid;
        align-content: center;
        justify-content: center;
        color: #fff;
        background-color: #232c37;
    }

    .right-middle{
        grid-row: 2;
        grid-column: 2;
        display: grid;
        align-content: center;
        justify-content: center;
        color: #ccc;
        background-color: #3d4953;
    }

    .right-bottom{
        grid-row: 3;
        grid-column: 2;
        display: grid;
        grid-template-columns: 40% 30% 30%;
        align-items: center;
        justify-items: center;
        text-align: center;
        color: #fff;
        background-color: #232c37;
    }
}

input::placeholder{
    font-size: 12px;
}

#peopleAmount::placeholder{
    font-size: inherit;
}

.verifyCircle{
    border: 1px solid #1fc4c7;
    border-radius: 50%;
    padding: 0px;
    width: 100px;
    height: 100px;
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 0 auto;
}

.display-1{
    font-weight: 500;
}

.form-control-date{
    max-width: 180px;
}

#enterButton:active{
    background-color: #248252!important;
}

#exitButton:active{
    background-color: #8c2d35!important;
}

.dataTables_wrapper{
    min-height: 350px;
}

#displayAfluenciaLink{
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    font-size: 10px;
}

#displayAfluenciaLink2{
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 1;
    font-size: 10px;
}

.siteShowLabel{
    font-size: 14px;
    color: #a4adb1;
}

.link-dropdown:hover{
    color: $blue;
}

.link-dropdown:active, .link-dropdown:focus{
    color: inherit;
    background-color: inherit;
}

.planPageLeftMenu{
    min-width: 300px;
    padding: 15px;
    border-right: 1px solid #dbdbdb;

    ul li{
        padding: 10px 0;
        border-bottom: 1px solid #dbdbdb;

        &:first-child{
            padding-top: 0;
        }

        &:last-child{
            border-bottom: 0;
        }

        a:not(.link-primary){
            color: #333;
        }
    }
}

.planPageContent{
    width: 100%;
    padding: 15px;
}

.grid-align-center{
    display: grid;
    align-items: center;
    justify-items: center;
}

.btnPlanWrapper{
    width: fit-content;
    border: 1px solid #dbdbdb;
    border-radius: 30px;
    display: flex;

    div{
        padding: 8px 35px;
        display: inline-block;
        cursor: pointer;

        &.active{
            background-color: $blue;
            color: #fff;

            &:first-child{
                border-radius: 30px 0 0 30px;
            }

            &:last-child{
                border-radius: 0 30px 30px 0;
            }
        }
    }
}

.form-control.form-control-bottom{
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding-left: 0;

    &:focus{
        outline: 0;
        box-shadow: none;
    }
}

.hideIcon::-webkit-calendar-picker-indicator {
    //background: none;
    pointer-events: none;
}

.photosBox{
    width: 100%;
    border:1px solid #ccc;
    min-height: 200px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
    color: #333;
    border: 0!important;
    background-color: transparent!important;
    background: transparent!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover{
    color: #333!important;
    border: 0!important;
    background-color: #ccc !important;
    background: #ccc !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button{
    border: 0!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled{
    filter: opacity(0.7);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover{
    background-color: transparent!important;
    background: transparent!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
    background-color: #ccc!important;
    background: #ccc!important;
}

.custom-control-input:checked~.custom-control-label::before{
    color: #fff;
    border-color: $blue;
    background-color: $blue;
}

.homeCardInner{
    padding: 20px;
    background-color: white;
    font-family: Quicksand;
}

.homeCardPercentage{
    width: 70px;
    height: 70px;
    border: 1px solid #333;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-content: center;

    span{
        font-family: Quicksand;
        font-size: 18px;
    }
}

.close{
    z-index: 10;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before{
    content: none!important;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after{
    content: none!important;
}

.selectSiteMulti{
    min-width: 350px;
    max-width: 50%!important;

    .multiselect__tags{
        padding: 14px 40px 0 8px;
    }
}

.planEachBox{
    padding: 25px;
    width: 125px;
    display: inline-block;
    text-align: center;
    border: 1px solid #333;
    border-radius: 3px;
    font-family: Quicksand;
    margin-right: 15px;
    cursor: pointer;

    &.planEachBoxActive{
        border: 2px solid #1fc4c7;
        p{
            color: $blue;
        }
    }

    p:first-child{
        font-size: 28px;
        font-weight: bold;
    }

    p:last-child{
        font-size: 15px;
        font-weight: bold;
    }
}

.currentPlanCard{
    padding: 30px;
    border: 1px solid #dee2e6;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Quicksand;
    border-radius: 5px;

    p:first-child{
        padding-right: 30px;
    }

    p:last-child{
        padding-left: 30px;
        text-align: right;
    }
}

.selectedPlanCheckWrapper{
    position:absolute;
    top: 0px;
    right: 10px;
    z-index: 1;

    i{
        font-size: 20px;
        color: #fff;
    }
}

.planSelectedWarningWrapper{
    font-family: Quicksand;

    i{
        font-size: 30px;
        color: $blue;
        padding-right: 10px;
    }
}

.activePlanWarning{
    background-color: $blue;
    padding: 10px 0;
    font-family: Quicksand;
    color:white;
}

.badge{
    padding: 0.6em 0.8em;
}

.dropdown-menu{
    border: 0;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    margin-top: 8px;
}

.dropdown-item{
    color: #6c757d;
    font-size: 14px;
    font-family: 'Quicksand';
    padding: 0.40rem 1.25rem;
}

.paymentTypeBox{
    display: inline-block;
    text-align: center;
    min-width: 160px;
    border: 2px solid #ced4da;
    border-radius: 5px;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;

    &.paymentTypeBoxActive{
        border: 2px solid $blue;

        .paymentTypeBoxCheck{
            color: $blue;
        }
    }

    .paymentTypeBoxCheck{
        position: absolute;
        top: 3px;
        right: 10px;
        font-size: 20px;
    }

    i{
        font-size: 30px;
    }
}

table#historyDatatable.dataTable.no-footer {
    border-bottom: 1px solid #dee2e6;
}

table#historyDatatable.dataTable thead th{
    border-bottom: 1px solid #dee2e6;
}

table#historyDatatable.dataTable tbody td{
    padding: 8px 20px;
}

.vue-dropzone>.dz-preview .dz-remove{
    margin-left: 18px;
    font-size: 12px;
    font-family: Quicksand;
}

.br-50{
    border-radius: 50px;
}

.font-size-15{
    font-size: 15px;
}

.homeSubsDropdown{
    transition: .4s ease-out;
    overflow: hidden;

    &.closed{
        max-height: 0!important;
    }
}

.myAlert{
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.noOutlineFocus:focus{
    box-shadow: none;
    outline: 0;
}

.easyLogoCss{
    height: 50px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.typeBox{
    display: inline-flex;
    padding: 8px 40px;
    border-radius: 5px;
    border: 1px solid #d6dade;
    color: #999;
    margin-right: 10px;
    cursor: pointer;

    &.selected{
        border: 1px solid #1fc4c7;
        color: #1fc4c7;
        background-color: rgb(31 196 199 / 5%);
    }
}

.loginLeftColDiv{
    display: grid;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    border-right: 2px solid #eee;
}

.loginImageClass{
    background-image: url("/images/loginImage.png");
}

.registerImageClass{
    background-image: url("/images/registerImage.png");
}

.loginTabLink{
    margin-right: 20px;
    font-weight: 800;

    &.active{
        color: $blue;
        border-bottom: 2px solid $blue;
    }
}

.color-grey{
    color: #929D9E;
}

.leftSideLoginQuote{
    color: $blue;
    font-size: 25px;
    line-height: 27px;
    /*padding: 0 40px;*/
}

.siteFormBigNumber{
    font-size: 50px;
    line-height: normal;
    font-weight: bold;
    padding-right: 15px;
}

.siteFormSectionTitle{
    font-size: 21px;
}

.helpColText{
    font-size: 12px;
}

.opacity05{
    opacity: 0.5;
}

.flatpickrReadonlyFix .form-control[readonly]{
    background-color: inherit;
}

#pageDatatable_paginate .paginate_button.current{
    background: transparent!important;
    background-color: transparent!important;
}

.datatableClass{
    border-bottom: 0!important;

    thead tr th{
        border-top: 0;
        font-size: 14px;
        padding: 15px 10px;
        color: #6c757d;
        font-weight: bold!important;
        border-bottom: 1px solid #dee2e6;
    }

    tbody tr td{
        border-top: 0;
        padding: 18px 10px;
        font-size: 14px!important;
        color: #6c757d!important;

        a{
            color: #6c757d;
        }
    }

    tbody tr:not(:last-child) td{
        border-bottom: 1px solid #dee2e6;
    }
}

#pageDatatable_wrapper{
    min-height: auto;
}

//MEDIA QUERIES
@media (orientation: landscape) {
    .portraitReverse {
        flex-direction: row;
    }

    .hideLandscape{
        display: none!important;
    }

    .pt-4-landscape{
        padding-top: 1.5rem;
    }

    .pb-4-landscape{
        padding-bottom: 1.5rem;
    }
}

@media (orientation: portrait) {
    .portraitReverse {
        flex-direction: column-reverse;
    }

    .hidePortrait{
        display: none!important;
    }

    #monitorGrid{
        grid-template-columns: 100%;
        grid-template-rows: 35% 10% 10% 35% 10%;

        .left-top{
            grid-row: 1;

            p{
                padding-top: 0!important;
                margin-bottom: 0;
            }

            .monitorLight{
                width: 25%;
            }
        }

        .left-bottom{
            grid-row: 2;
        }

        .right-top{
            grid-row: 3;
            grid-column: 1;

            i{
                color: #333;
            }
        }

        .right-middle{
            grid-row: 4;
            grid-column: 1;
        }

        .right-bottom{
            grid-row: 5;
            grid-column: 1;

            grid-template-columns: 33.3% 33.3% 33.3%;
        }
    }

    @media only screen and (min-height: 1600px) {
        #monitorGrid{
            grid-template-rows: 40% 10% 7% 36% 7%;

            .right-middle h3{
                font-size: 40px;
            }

            .left-top{
                .monitorLight{
                    width: 35%;
                    max-width: 355px;
                }

                h5{
                    font-size: 2.25rem;

                    i{
                        font-size: 50px!important;
                    }
                }

                p{
                    font-size: 30px;
                }
            }
        }
    }

    @media only screen and (min-height: 1900px) {
        #monitorGrid{
            .left-top{
                .monitorLight{
                    width: 35%;
                    max-width: 450px;
                }
            }

            .right-middle h3{
                font-size: 40px;
            }

            .left-bottom > div .eachMonitorIcon{
                width: 120px;
                height: 120px;

                img{
                    height: 60px;
                }
            }
        }
    }
}

@media only screen and (max-height: 600px) {
    #monitorGrid.smallGrid .monitorLight{
        max-width: 250px!important;
    }
}

@media only screen and (min-width: 1600px) {
    #monitorGrid {
        .right-middle h3{
            font-size: 40px;
        }
    }
}

@media only screen and (min-width: 1600px) {
    #monitorGrid.smallGrid{
        grid-template-rows: 15% 60% 25%;


        .monitorLight{
            max-width: 400px!important;
        }

        .left-top{
            h5{
                font-size: 45px;
            }

            p{
                font-size: 30px;
            }
        }

        .left-bottom{
            > div{
                .eachMonitorIcon{
                    width: 125px;
                    height: 125px;

                    img{
                        height: 70px;
                    }
                }

                span{
                    padding-top: 12px;
                    font-size: 25px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    #monitorGrid .left-bottom>div span{
        font-size: 10px;
    }

    .right-middle h3{
        font-size: 1.4rem;
    }
}

@media only screen and (min-width: 992px) {
    .border-right-desktop{
        border-right: 1px solid #dee2e6;
    }
}

@media only screen and (max-width: 991px) {
    .customSearchForm{
        border-radius: 0px;
        border: 0px solid #fff;
        overflow: hidden;
    }

    .roundedModal{
        border-radius: 1rem;

        .leftSidePayModal{
            border-radius: 0rem 0rem 1rem 1rem;
        }
    }

    .mobileReverse {
        flex-direction: column-reverse;
    }

    .customSearchFormFirstDiv{
        margin-bottom: 15px;

        button{
            padding: 10px 0;
            border-radius: 5px;
        }
    }

    .customSearchFormFirstInputWrapper{
        background-color: #31424a;
        border-bottom: 0px solid #eee;
        border-radius: 5px 0 0 5px;

        input{
            border-left: 0;
        }
    }

    .customSearchFormSearch{
        border-radius: 0 5px 5px 0;
    }

    .customSearchFormSelectWrapper{
        background-color: #31424a;

        select{
            padding: 0 30px;
            width: 95%;
        }
    }
}

.sideModal{
    height: 100%;
    width: 400px;
    background-color: white;
    position: fixed;
    top: 0;
    left: -402px;
    transition: .5s ease-out;
    z-index: 25;
    box-shadow: 2px 0px 13px -6px #000;

    &.open{
        left: 0;
    }
}

.agree-term {
    display: flex;
    align-items: center;
}

.navbar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.top-menu{
    background-color: #fff;
    //box-shadow: 0 0 7px 0px rgba(154, 161, 171, 0.3);
    transition: .5s ease-out;
    display: block;
    position: fixed;
    top: 70px;
    width: 100%;
    z-index: 9;
    border-bottom: 1px solid #f8f9fd;
}

.second-level-ul{
    padding-left: 40px;
    overflow: hidden;
    height: 0;
    transition: .4s ease-out;

    &.open{
        height: 165px;
    }

    li a{
        padding: 8px 20px;
        color: #6e768e;
        display: block;
        position: relative;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        font-size: 14px;
    }
}

.top-menu ul{
    margin-bottom: 0;

    li{
        display: inline-block;
        padding: 10px 0;
        margin-right: 25px;

        /*&:first-child a{
            padding-left: 0;
        }*/

        &.active{
            border-bottom: 3px solid $blue;

            a{
                color: $blue;
            }
        }

        a{
            color: #333;
            display: flex;
            align-items: center;
            padding: 13px 5px 11px 5px;
            position: relative;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            font-size: 15.2px;

            &:hover{
                color: $blue;
            }

            i{
                display: inline-block;
                line-height: 1.0625rem;
                margin: 0 10px 0 3px;
                text-align: center;
                vertical-align: middle;
                width: 20px;
                font-size: 20px;
                transition: .3s ease-out;

                &.fa-chevron-right{
                    margin-right: 0;
                }
            }
        }
    }
}

.siteGraphDivWrapper{
    position: absolute;
    top: 50px;
    right: 15px;
    width: 100%;
    max-width: 55%;
}

.contentOfPage{
    margin-top: 160px;
}

.rotate{
    transform: rotate(90deg);
}

.custom-breadcumbs li{
    display: inline-block;
    font-weight: 500;
    font-size: 13px;

    &.active{
        color: #777;
    }

    a{
        color: #aaa;

        &:hover{
            color: #777;
        }
    }
}

.btn-success{
    color: #fff;
    background-color: #3bc47e;
    border-color:#3bc47e;

    &:hover, &:focus, &:active{
        color: #fff;
        background-color: #35b172!important;
        border-color:#35b172!important;
    }
}

.btn-primary{
    color: #fff;
    background-color: $blue!important;
    border-color:$blue!important;

    &:hover, &:focus, &:active{
        color: #fff;
        background-color: #19a0a2!important;
        border-color:#19a0a2!important;
    }
}

.btn-outline-primary{
    color: $blue;
    border-color:$blue!important;

    &:hover, &:focus, &:active{
        color: #fff;
        background-color: $blue!important;
        border-color:$blue!important;
        box-shadow: none!important;
    }
}

.btn-warning{
    color: #fff;
    background-color: #ff9d0a;
    border-color: #ff9d0a;

    &:hover, &:focus, &:active{
        color: #fff;
        background-color: #ec9209!important;
        border-color:#ec9209!important;
    }
}

.btn-danger{
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;

    &:hover, &:focus, &:active{
        color: #fff;
        background-color: #ca313f!important;
        border-color:#ca313f!important;
    }
}

.background-primary{
    background-color: $blue;
}

.color-primary{
    color: $blue;
}

.color-success{
    color: #3bc47e;
}

.color-danger{
    color: #dc3545;
}

.color-danger-important{
    color: #dc3545!important;
}

.color-warning{
    color: #ff9d0a;
}

.link-danger{
    color: #dc3545;
    transition: .2s ease-out;

    &:hover{
        color: #ca313f;
    }
}

.link-primary{
    color: $blue;
    transition: .2s ease-out;

    &:hover{
        color: #19a0a2;
    }
}

.shadow{
    //box-shadow: 2px 2px 5px 0px rgba(56, 65, 74, 0.3) !important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.05) !important;
}

.hard-shadow{
    //box-shadow: 2px 2px 5px 0px rgba(56, 65, 74, 0.3) !important;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1) !important;
}

.loged-navbar-back{
    padding: 0;
    height: 70px;
    background-color:#fff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    border-bottom: 1px solid #dbdbdb;
}

.content-page{
    padding: 0;
    margin-left: 0;
    margin-top: 0;
}

//BURGER ICON
#hamburger {
    width: 30px;
    height: 30px;
    display: block;
    position: relative;
    float: right;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}
#hamburger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #333;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
#hamburger span:nth-child(1) {
    top: 2px;
}
#hamburger span:nth-child(2) {
    top: 12px;
}
#hamburger span:nth-child(3) {
    top: 22px;
}
#hamburger.open span:nth-child(1) {
    top: 14px;
    transform: rotate(135deg);
}
#hamburger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}
#hamburger.open span:nth-child(3) {
    top: 14px;
    transform: rotate(-135deg);
}

.loggedBody{
    .alert-danger{
        color: #fff;
        background-color: rgb(220 53 69 / 0.80);
        border-color: #dc3545;
        text-align: center;
        font-weight: 600;
    }

    .alert-warning{
        color: orangered;
        background-color: #fff;
        border-color: orangered;
        text-align: center;
        font-weight: 600;
    }
}

.alert-danger{
    color: white;
    background-color: #1e1d1d;
    border-color: #dc3545;
    text-align: center;
}

.alert-warning{
    color: white;
    background-color: #1e1d1d;
    border-color: orangered;
    text-align: center;
}

//BURGER ICON


.lightTable{
    thead tr th{
        font-weight: 400;
        background-color: transparent;
    }

    tbody tr td{
        font-size: 14px;
        color: #333;
        vertical-align: middle;

        i{
            font-size: 20px;
        }
    }

    &.profiles thead tr th:first-child{
        padding-left: 1rem!important;
    }

    &.profiles tbody tr td:first-child{
        padding-left: 1rem!important;
    }
}

.text-muted-plus{
    color: #cecece !important;
}

@media only screen and (min-width: 768px) {
    .hiddenButtonsOnMobile{
        display: none;
    }

    .hidden-desktop{
        display: none!important;
    }

    .navbar-toggle {
        display: none;
    }

    #hamburger {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .hiddenButtonsOnDesktop {
        display: none;
    }

    #generic_price_table .generic_content{
        margin-bottom: 25px;
    }

    .top-info{
        display: block;

        .page-title{
            padding-bottom: 5px;
        }
    }

    .hideMobile{
        display: none;
    }

    .topbarNav {
        display: none;
    }

    .rightTopBarDesktop {
        display: none !important;
    }

    .contentOfPage{
        margin-top: 85px;
    }

    .top-menu{
        position: fixed;
        width: 100%;
        left: -100%;
        z-index: 9;
        min-height: calc(100vh - 70px);
        box-shadow: none;

        ul{
            padding-top: 15px;

            li{
                display: block;
                border: 0;
                margin-right: 0;
                padding: 0;

                &.active{
                    border: 0;
                }
            }
        }


        &.open{
            left: 0;
        }
    }
}

@media only screen and (max-width: 599px) {
    .homeBgImg{
        background-image: none;
    }

    .publicHomeBannerFormDiv{
        padding: 25px 0;
    }
}

@media only screen and(max-width: 575px){
    .hidden-xs{
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .choosePaymentModalFlex{
        flex-direction: column;
    }
}

@media only screen and (min-width: 400px) {
    .navSidebar{
        width: 319px;
    }
}
